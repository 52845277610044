<template lang="html">
  <modal-anim>
    <div class="modal sliderModal">
      <div class="overlay gradient modalOverlay down" v-show="open" @click.prevent="$emit('closeModal')">
        <i class="text--white medium icons icon-close white--text closeModalIcon"></i>
      </div>
      <img-slider :slides="imgs" :showOnOpen="img_current_index"></img-slider>
    </div>
  </modal-anim>
</template>

<script>
import imgSlider from '../sliders/imgSlider.vue'
export default {
  components:{
    'img-slider': imgSlider
  },
  props:['imgs','open','img_current_index']
}
</script>
<style>
  .overlay.modalOverlay.down {
    z-index: -1;
  }
</style>
