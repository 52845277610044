<template>
  <img
    :src="imgDatas.sizes.xlarge"
    :width="imgDatas.sizes[`${quadImg ? 'large_thumb' : 'xxlarge'}-width`]"
    :height="imgDatas.sizes[`${quadImg ? 'large_thumb' : 'xxlarge'}-height`]"
    :sizes="sizes"
    :srcset="srcset"
    :alt="imgDatas.alt || imgDatas.title"
    :style="{ height: quadImg ? '100%' : 'auto' }"
    class="img-responsive"
  >
</template>

<script>
export default {
  props:['imgDatas','sizes','showLarge','quadImg'],
  data: () => ({
    loaded: false
  }),
  mounted () {
    this.imgIsLoaded()
  },
  computed:{
    srcset(){
      if(this.quadImg){
        return this.imgDatas.sizes.medium_thumb + ' 450w,' + this.imgDatas.sizes.large_thumb + ' 600w'
      }

      let stringEnd = '';
      // if(this.imgDatas.sizes.medium_large){
      //   stringEnd = ' ,' + this.imgDatas.sizes.medium_large + ' 525w'
      // }
      if(this.showLarge){
        stringEnd = stringEnd + ' ,' + this.imgDatas.sizes.large + ' 1024w'
        // stringEnd = stringEnd + ' ,' + this.imgDatas.sizes.xlarge + ' 1280w'
        stringEnd = stringEnd + ' ,' + this.imgDatas.sizes.xxlarge + ' 1600w'
      }
      return this.imgDatas.sizes.medium + ' 300w,' + this.imgDatas.sizes.medium_custom + ' 450w'  + stringEnd
    }
  },
  methods: {
    imgIsLoaded () {
      if (this.loaded || !process.client) return
      if (this.$el.complete) {
        this.loaded = true
        this.$emit('img-loaded')
      }
      window.requestAnimationFrame(this.imgIsLoaded)
    }
  }
}
</script>
