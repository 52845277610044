<template lang="html">
  <transition-group enter-active-class="fadeIn" leave-active-class="fadeOut" tag="div" :class="'ksSlider imgSlider'">
    <div class="slide media" v-for="(img,index) in slides" v-show="current_index == index" :key="img.ID" :class="{'active': current_index == index}">
      <figure>
        <img-srcset :imgDatas="img" :showLarge="true"></img-srcset>
        <figcaption>{{img.caption}}</figcaption>
      </figure>
      <div class="sliderControls">
        <span class="prev" @click="changeSlide(index - 1)" v-if="index > 0">
          <i class="icons icon-arrow-left"></i>
        </span>
        <span class="next" @click="changeSlide(index + 1)" v-if="index < slides.length - 1">
          <i class="icons icon-arrow-right"></i>
        </span>
      </div>
    </div>
  </transition-group>
</template>

<script>
let sliderTimeout;
import imgSrcset from '../images/imgSrcset.vue'
export default {
  props:['slides','duration','showOnOpen'],
  components:{
    'img-srcset': imgSrcset
  },
  data(){
    return {
      current_index:-1
    }
  },
  mounted(){
    const vm = this;
    vm.$nextTick(()=>{
      vm.changeSlide()
    })
  },
  methods:{
    changeSlide(index){
      clearTimeout(sliderTimeout);
      sliderTimeout = '';
      const vm = this;
      if(index == 0 ||!index && vm.current_index == vm.slides.length - 1){
        vm.current_index = 0;
      } else {
        if(!index){
          vm.current_index = vm.current_index + 1;
        } else {
          vm.current_index = index;
        }
      }
      if(vm.duration){
        sliderTimeout = setTimeout(()=>{
          vm.changeSlide();
        },vm.duration);
      }
    },
    getIndexById(medias,id){
      const vm = this;
      let ind = _.findIndex(slides,(o)=>{
        return o.id == id;
      });
      vm.current_index = ind;
    }
  },
  watch:{
    showOnOpen(val){
      this.current_index = val;
    }
  },
  beforeDestroy(){
    this.current_index = 0;
  }

}
</script>
