<template lang="html">
  <v-layout class="cardsList masonryList" row wrap>
    <!-- <trans-stagg-fade> -->
      <v-flex xs12 sm6 lg4 class="masonryItem" :key="'real'" tag="article">
        <div class="card imgCard z-depth-0 borders">
          <svg viewBox="0 0 700 700" preserveAspectRatio="xMaxYMax meet">
            <rect width="700" height="700" style="fill:none" />
          </svg>
          <div class="overlay contentWrapper centered  d-flex align-center">
            <div class="row">
              <div class="col s12">
                <h3><span>A la une</span>Nos dernières réalisations</h3>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 sm6 lg4 class="masonryItem" v-for="(project,index) in projectsToshow.slice(0,3)" :key="project.id" :data-index="index" tag="article">
          <project-card :project="project" v-if="project"></project-card>
      </v-flex>
      <v-flex xs12 sm6 lg4 class="masonryItem" :key="'real2'" tag="article">
        <div class="card imgCard borders z-depth-0">
          <svg viewBox="0 0 700 700" preserveAspectRatio="xMaxYMax meet">
            <rect width="700" height="700" style="fill:none" />
          </svg>
          <div class="overlay contentWrapper centered d-flex align-center">
            <div class="row">
              <div class="col s12">
                <h3 class="right-align text-right"><span>En cours</span>Visite de chantier</h3>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 sm6 lg4 class="masonryItem" :key="'inProgr'" v-if="project_in_progress && project_in_progress.acf.project_in_progress && project_in_progress.acf.project_in_progress.length" tag="article">
        <div class="card imgCard waves-effect waves-light borders cursor-pointer" @click.prevent="modal_inprogress_opened = true" href="#">
          <figure class="card-image">
            <img class="img-responsive" :src="project_in_progress.acf.project_in_progress[0].sizes.large_thumb" :alt="project_in_progress.acf.project_in_progress[0].alt">
          </figure>
        </div>
      </v-flex>
    <!-- </trans-stagg-fade> -->
    <div v-if="modal_inprogress_opened && project_in_progress && project_in_progress.acf.project_in_progress && project_in_progress.acf.project_in_progress.length">
      <img-modal :imgs="project_in_progress.acf.project_in_progress" :open="modal_inprogress_opened" v-on:closeModal="modal_inprogress_opened = false" :img_progress_current_index="img_progress_current_index" v-show="modal_inprogress_opened" :class="{'active': modal_inprogress_opened}"></img-modal>
    </div>
  </v-layout>
</template>

<script>
import projectCard from '../cards/projectCard.vue'
import imgModal from '../modals/imgModal.vue'
export default {
  name:'cardsListHome',
  props:['projects'],
  components:{
    'project-card': projectCard,
    'img-modal': imgModal
  },
  data(){
    return {
      modal_inprogress_opened: false,
      img_progress_current_index: 0
    }
  },
  computed:{
    project_in_progress(){
      return this.projects.find(o => o.acf.project_in_progress && o.acf.project_in_progress.length)
    },
    projectsToshow(){
      return this.projects.filter(p => p.acf.show_in_home && p.post_images)
    }
  },
}
</script>
