<template>
  <v-card nuxt :to="{name:'nos-realisations-slug',params:{ slug:project.slug }}" class="card imgCard borders fill-height" :aria-label="`Voir la réalisation ${project.title.rendered}`">
    <v-responsive :aspect-ratio="1">
      <figure class="card-image fill-height" v-if="project.featured_media">
        <img-srcset :quadImg="true" :imgDatas="project.post_images" :sizes="'(max-width: 640px) 100vw,30vw'"></img-srcset>
      </figure>
      <div class="overlay contentWrapper hoverEffect gradient text-xs-right right-align white--text">
        <div class="container pa-0">
          <div class="col s12">
            <h3 class="right-align text-right white-text smallFont">
              <span class="projectType mb-8">
                <span v-for="(type, index) in (project.project_types || [])" :key="type.term_id">
                  {{type.name}}
                </span>
              </span>
              <span v-html="project.title.rendered"></span>
            </h3>
          </div>
        </div>
      </div>
    </v-responsive>
  </v-card>
</template>
<script>
import imgSrcset from '../images/imgSrcset.vue'
export default {
  components:{
    'img-srcset': imgSrcset
  },
  props:['project'],
}
</script>
